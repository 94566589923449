.c-settings-page__form--container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.c-settings-page__form-section-title,
.c-settings-page__branding-color-title {
  font-weight: 600;
  font-size: 0.875rem;
}

.c-settings-page__form-stripe-card {
  align-items: center;
  border: 1px solid #cccfd9;
  border-radius: 5px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  gap: var(--g-spacing-2x-small);
  padding: var(--g-spacing);
}

.c-settings-page__form-field__stripe {
  align-items: flex-end;
  display: grid;
  padding-top: var(--g-spacing);
}

.c-settings-page__form-submit {
  display: flex;
  justify-content: center;
}

.c-settings-page__logo {
  border: 1px solid #cbccce;
  border-radius: 0.625rem;
  display: flex;
  justify-content: center;
  position: relative;
}

.c-settings-page__logo-image {
  width: 100%;
  border-radius: 0.625rem;
}

.c-settings-page__remove-logo-button {
  position: absolute;
  padding: var(--g-spacing-x-small);
  right: 0;
  top: 0;
}

.c-settings-page__branding-color-title {
  display: inline-block;
  width: 100%;
}

@media screen and (min-width: 48em) {
  .c-settings-page__form-row {
    display: flex;
    gap: var(--g-spacing-large);
  }

  .c-settings-page__form-field {
    width: 50%;
  }

  .c-settings-page__form--container {
    max-width: 95.25rem;
    width: 70%;
  }

  .c-settings-page__form-submit {
    justify-content: flex-end;
  }
}
