.c-organisation-menu .c-organisation-navigation .c-dropdown-navigation__list-item,
.c-organisation-navigation__title {
  font-size: var(--g-font-size-small);
}

.c-organisation-menu .c-organisation-navigation .c-dropdown-navigation__list-item {
  display: flex;
  align-items: flex-start;
}

.c-organisation-menu .c-organisation-navigation .c-dropdown-navigation__list-item {
  padding: var(--g-spacing-x-small) var(--g-spacing-large);
}

.c-organisation-menu .c-organisation-navigation .c-dropdown-navigation__list-item:last-child,
.c-organisation-navigation__title {
  padding: var(--g-spacing-x-small) var(--g-spacing);
  font-weight: var(--g-font-weight);
}

.c-organisation-menu .c-organisation-navigation__icon {
  display: none;
}

.c-organisation-menu .c-dropdown-toggle--open {
  background-color: #1a3f7a;
  border-radius: var(--g-border-radius-small);
}

.c-organisation-menu .c-dropdown-toggle--open,
.c-organisation-menu .c-dropdown-toggle {
  padding: 0.3rem 0.55rem 0.6rem;
  position: relative;
  top: var(--g-spacing-3x-small);
}

.c-organisation-menu .c-organisation-navigation__link,
.c-organisation-navigation__name {
  display: block;
}

.c-organisation-menu .c-organisation-navigation {
  right: 4rem;
  top: 3.25rem;
}
