ul li,
ol li {
  margin-left: 0;
}

.c-navigation-button {
  background-color: transparent;
}

.c-sidebar-button-label {
  display: block;
  font-size: var(--g-font-size-small);
  font-weight: var(--g-font-weight-light);
  padding-bottom: var(--g-font-size-2x-small);
}

.c-sidebar__close-button {
  padding: var(--g-spacing);
  position: absolute;
  right: 0;
  top: 0;
}

.c-sidebar-section--aligned {
  display: flex;
  flex-direction: column;
  position: relative;
}

.c-sidebar-section__tooltip {
  position: absolute;
  right: 0;
  top: 0;
}

.c-sidebar-save-button {
  background-color: var(--g-color-grey-50);
  bottom: 0;
  display: flex;
  gap: var(--g-spacing);
  justify-content: center;
  padding: var(--g-spacing);
  position: fixed;
  right: 0;
  width: var(--c-sidebar-max-width);
}

.bg-gradient {
  background: linear-gradient(45deg, #173567, #972868);
}

.bg-gradient-wave {
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-gradient-wave--whole {
  background-image: url(/images/background-whole.svg);
}

.bg-gradient-wave--minimal {
  background-image: url(/images/background-gradient-minimal.svg);
}

.c-search-filter__input .c-input__container {
  width: 28.5rem;
}

.c-settings-button,
.c-select-with-search__search-button,
.c-sidebar__toggle-reveal-button,
.c-navigation-button,
.c-select-with-search__list .c-button-list__item {
  cursor: pointer;
}

.c-button-list__item {
  background-color: var(--g-color-white);
}

.c-toggle-switch__slider {
  bottom: var(--g-spacing-small);
}

.c-sidebar__container {
  z-index: 3;
}

.c-sidebar__container--left {
  top: 4.4rem;
  padding-top: var(--g-spacing-3x-large);
  position: absolute;
  height: calc(100vh + 1.975rem);
}

.c-color-picker {
  margin-top: var(--g-spacing);
}

.color-picker__select {
  padding: var(--g-spacing);
}

.c-color-picker-hide {
  display: none !important;
}

.c-color-picker-show {
  display: block !important;
}

.c-color-picker-preview__button {
  cursor: pointer;
}

.c-dropdown-navigation__list-item-button {
  background-color: transparent;
  cursor: pointer;
  font-weight: var(--g-font-weight-bold);
  padding: var(--g-spacing-2x-small) 0;
}

@media screen and (max-width: 17em) {
  .c-breadcrumb-with-avatar .c-avatar {
    max-height: var(--g-spacing-2x-large);
    min-width: var(--g-spacing-2x-large);
  }
}
