.c-dashboard-sidebar-general-view {
  padding-bottom: var(--g-spacing-6x-large);
}

.c-dashboard-sidebar-general-view__logo {
  position: relative;
}

.c-dashboard-sidebar-general-view__logo-asset {
  width: 34rem;
  height: 30rem;
}

.c-remove-asset-button {
  padding: var(--g-spacing);
  position: absolute;
  right: 0;
  top: 0;
}
