:root {
  /* Fonts */
  --font-header: rift;
  --font-body: calibri;

  /* COLOURS */
  --black: #000;
  --burgandy-400: #972868;
  --grey-900: #2a2e2f;
  --grey-800: #42536e;
  --grey-400: #a8b2b4;
  --grey-300: #cbccce;
  --grey-200: #ecedf0;
  --grey-100: #f6f6f7;
  --green-400: #4bc2ca;
  --green-500: #39acb4;
  --green-600: #239098;
  --green-700: #15666c;
  --green: #4caf50;
  --color-primary: #4bc2ca;
  --red-400: #f44336;
  --red-300: #ff4d4d;
  --yellow-400: #ffc96c;
  --white: white;

  /* SPACING */
  --spacing-base: 0.25rem;
  --spacing-0-5: calc(var(--spacing-base) / 2);
  --spacing-1: calc(var(--spacing-base) * 1);
  --spacing-2: calc(var(--spacing-base) * 2);
  --spacing-3: calc(var(--spacing-base) * 3);
  --spacing-4: calc(var(--spacing-base) * 4); /* 1rem */
  --spacing-5: calc(var(--spacing-base) * 5);
  --spacing-6: calc(var(--spacing-base) * 6);
  --spacing-7: calc(var(--spacing-base) * 7);
  --spacing-8: calc(var(--spacing-base) * 8); /* 2rem */
  --spacing-9: calc(var(--spacing-base) * 9);
  --spacing-10: calc(var(--spacing-base) * 10);
  --spacing-11: calc(var(--spacing-base) * 11);
  --spacing-12: calc(var(--spacing-base) * 12); /* 3rem */
  --spacing-13: calc(var(--spacing-base) * 13);
  --spacing-14: calc(var(--spacing-base) * 14);
  --spacing-15: calc(var(--spacing-base) * 15);
  --spacing-16: calc(var(--spacing-base) * 16); /* 4rem */
  --spacing-17: calc(var(--spacing-base) * 17);
  --spacing-18: calc(var(--spacing-base) * 18);
  --spacing-19: calc(var(--spacing-base) * 19);
  --spacing-20: calc(var(--spacing-base) * 20); /* 5rem */
  --spacing-24: calc(var(--spacing-base) * 24); /* 6rem */
  --spacing-28: calc(var(--spacing-base) * 28); /* 7rem */
  --spacing-32: calc(var(--spacing-base) * 32); /* 8rem */
  --spacing-40: calc(var(--spacing-base) * 40); /* 10rem */
  --spacing-48: calc(var(--spacing-base) * 48); /* 11rem */
  --spacing-52: calc(var(--spacing-base) * 52); /* 13rem */
  --spacing-60: calc(var(--spacing-base) * 60); /* 15rem */
  --spacing-72: calc(var(--spacing-base) * 72); /* 18rem */
  --spacing-80: calc(var(--spacing-base) * 80); /* 20rem */
  --spacing-88: calc(var(--spacing-base) * 88); /* 22rem */
}

html {
  font-size: 0.875rem;
  scroll-behavior: smooth;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media (min-width: 768px) {
  html {
    font-size: 1rem;
  }

  h1,
  .h1 {
    font-size: 4.5rem;
    line-height: 1;
  }

  h2,
  .h2 {
    font-size: 3.5rem;
    line-height: 1.1;
  }

  h3,
  .h3 {
    font-size: 1.5rem;
    line-height: 1.3;
  }

  h4,
  .h4 {
    font-size: 1.15rem;
    line-height: 1.4;
  }
}

.trend-cards {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
p {
  margin-bottom: 0;
}

.icon_project_paid {
  width: 20px;
  height: 20px;
  color: var(--grey-400);
}

.icon_project_paid.paid {
  color: #cddc39;
}

.insight-card {
  width: 40rem;
  height: 25rem;
}

.insights-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
