.c-payouts-table__period-select {
  width: 13rem;
  height: 2.813rem;
  padding: 0 var(--g-spacing-small);
}

.c-payouts-table__breakdown-button .c-navigation-button__icon {
  width: 0.65rem;
  height: 0.65rem;
  transform: rotate(270deg);
}
