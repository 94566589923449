.c-partner-manager-page-left-sidebar__list {
  display: flex;
  flex-direction: column;
  gap: var(--g-spacing-x-small);
}

.c-partner-manager-page-left-sidebar__list-item,
.c-partner-manager-page-left-sidebar__partner-code {
  align-items: center;
  border-radius: var(--g-border-radius-small);
  display: flex;
  font-size: var(--g-font-size-small);
  gap: 0.563rem;
  margin: 0;
  padding: var(--g-spacing-x-small) var(--g-spacing-small);
}

.c-partner-manager-page-left-sidebar__list-item--active {
  background-color: var(--g-color-grey-50);
}

.c-partner-manager-page-left-sidebar__partner-code {
  background-color: #3e3167;
  color: var(--g-color-white);
  font-size: var(--g-font-size-x-small);
  padding: var(--g-spacing-x-small);
  padding-left: var(--g-spacing-small);
}

.c-partner-manager-page-left-sidebar__list-item__icon {
  height: 0.813rem;
  width: 0.813rem;
}

.c-partner-manager-page-left-sidebar__partner-code-icon {
  height: var(--g-spacing-large);
  width: var(--g-spacing-large);
}

.partner-management__sidebar .c-sidebar__toggle-reveal-button {
  z-index: 3;
  top: 7.1rem;
}

.partner-management__sidebar .c-sidebar__container--left {
  padding-top: var(--g-spacing);
}

.c-partner-manager-page-left-sidebar__partner-code {
  display: flex;
  align-items: center;
}

.c-partner-manager-page-left-sidebar__partner-code-button {
  background-color: transparent;
  border: none;
  color: var(--g-color-white);
  cursor: pointer;
  font-size: var(--g-font-size-small);
  padding: 0;
  display: flex;
  gap: var(--g-spacing-2x-small);
  align-items: center;
}
