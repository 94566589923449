.c-container {
  padding: 1rem 4rem;
  width: 100%;
  min-height: 100vh;
}

.c-container--xs {
  max-width: 48rem;
}

.c-contact-form-container {
  max-width: 45rem;
  margin: auto;
}

.c-container--large {
  max-width: 100rem;
}

.c-container--transition {
  transition: var(--g-transition-time) ease-in-out;
}

.c-page-layout-template {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.c-page-layout-template .c-dropdown-toggle {
  background-color: transparent;
  cursor: pointer;
}

.c-page-layout-template .c-dropdown-navigation__list {
  margin: 0;
}
.c-page-layout-template__main {
  width: 100%;
  transition:  var(--g-transition-time) ease-in-out;
}

.l-container:has(> .partner-management__sidebar--open) > .c-page-layout-template__main {
  transform: translateX(15.5rem);
  width: calc(100% - 15.5rem);
}

@media screen and (min-width: 125em) {
  .c-container {
    width: 90%;
  }

  .c-container--small {
    max-width: 100rem;
  }
}
