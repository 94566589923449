.c-projects-table__information {
  align-items: flex-start;
}

.c-projects-table__information .c-information-box__icon {
  margin-top: var(--g-spacing-3x-small);
}

.c-projects-table__information-content {
  display: flex;
  flex-direction: column;
}

.c-projects-table .c-table__tbody-tr .c-table__tbody-td--has-button {
  position: relative;
}

.c-projects-table .c-table__tbody-tr .c-table__tbody-td--has-button button {
  position: absolute;
  top: 50%;
  left: 99%;
  transform: translate(-100%, -50%);
}

.c-projects-table__projects-cell {
  align-items: center;
  display: flex;
  gap: var(--g-spacing-small);
}

.c-projects-table__new-window-icon {
  height: var(--g-spacing);
  width: var(--g-spacing-6x-large);
  position: relative;
  top: var(--g-spacing-3x-small);
}
