.c-partner-management-page__switch-account-label {
  align-items: center;
  display: flex;
  gap: var(--g-spacing-x-small);
}

.c-partner-management-page__switch-account-label .c-label .h-spacing-small {
  margin-bottom: 0;
  font-weight: var(--g-font-weight-semibold);
  font-size: var(--g-font-size-small);
}

.c-partner-management-page__switch-account-select .c-select-with-search__search-button {
  background-color: var(--g-color-grey-50);
}

.c-partner-management-page__switch-account-select .c-select-with-search__search-button-icon {
  border: 1px solid var(--g-color-black);
  border-radius: 50%;
}

.partner-management__sidebar .c-sidebar__toggle-reveal-button {
  z-index: 3;
  top: 7.1rem;
}

.partner-management__sidebar .c-sidebar__container--left {
  padding-top: var(--g-spacing);
}
