.linechart-legend-ul {
  list-style-type: none;
}

.linechart-legend-ul > li {
  display: flex;
  align-items: center;
  gap: 10px;
}

.linechart-chart {
  width: 25rem;
  height: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.linechart-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 40px;
}

.linechart-dot-legend {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
