.c-form__field {
  padding-bottom: var(--g-spacing);
}

.c-form__footer {
  display: flex;
  justify-content: center;
  padding-top: var(--g-spacing);
}

@media screen and (min-width: 48em) {
  .c-form__row {
    display: flex;
    gap: var(--g-spacing-large);
  }

  .c-form__field--half {
    width: 50%;
  }

  .c-form__footer {
    justify-content: flex-end;
  }
}
