.c-partners-page {
  display: block;
  margin: auto;
}

.c-partners-page__header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.c-partners-page__header-text {
  font-size: var(--g-font-size-large);
  font-weight: var(--g-font-weight-semibold);
  padding-top: var(--g-spacing-2x-small);
}

.c-button-card__list {
  display: flex;
  justify-content: center;
  gap: var(--g-spacing-x-small);
}

.c-button-card__list-item {
  list-style-type: none;
}

.c-button-card-item {
  border: none;
  background-color: var(--g-color-white);
  cursor: pointer;
}
