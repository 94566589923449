.chart-picker-ul {
  display: flex;
  gap: 10px;
  list-style-type: none;
}

.chart-picker-ul > li {
  border: 1px solid var(--grey-100);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chart-picker-ul > li > svg {
  width: 30px;
  height: 30px;
  padding: 7px;
}

.chart-picker-ul > li.active {
  background-color: var(--grey-100);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border: 1.5px solid black;
  border-radius: 4px;
}

.chart-picker-container {
  display: flex;
  justify-content: end;
  margin-right: 50px;
}
