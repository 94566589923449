.doughnut-container {
  margin: 0 auto;
  width: 14rem;
  height: 14rem;
}

.doughnut-title {
  text-align: center;
}
.doughnut-value {
  text-align: center;
}
